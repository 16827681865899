import React from "react";
import { makeStyles } from "@mui/styles";
import CustomAppBar from "./components/CustomAppBar";
import ScaledImage from "./components/ScaledImage";
import BlogConfig from "./data/blog.json";
import MediaConfig from "./data/media.json";

const useStyles = makeStyles(() => ({
  outerContainer: {
    overflow: "auto",
    backgroundColor: "#F3F3F3",
  },
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    overflow: "auto",
  },
  container: {
    width: "700px",
    minWidth: "700px",
    paddingTop: "120px",
  },
  spacer: {
    height: "100%",
    flexBasis: 0,
    flexGrow: 1,
  },
  horizontalSpacer: {
    width: "100%",
    height: "10px",
  },
  horizontalBigSpacer: {
    width: "100%",
    height: "40px",
  },
  blogContainer: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #E4E4E4",
  },
  title: {
    padding: "5px 10px",
    font: "30px Helvetica, Arial, sans-serif",
  },
  description: {
    padding: "5px 10px",
    font: "14px Helvetica, Arial, sans-serif",
    fontWeight: "300 !important",
  },
  headline: {
    width: "100%",
    height: "60px",
    color: "#000000",
    font: "40px Allura",
    paddingLeft: "20px",
  },  
}));

function BlogController() {
  const classes = useStyles();

  React.useEffect(() => {
    document.title = "Blog | asenka.art";
  }, []);
  
  return (
    <div className={classes.outerContainer}>
      <div className={classes.root}>
        <div className={classes.spacer} />
        <div className={classes.container}>
          <div className={classes.headline}>Blog</div>
          {
            BlogConfig.map((item, index) => {
              const mediaInfo = MediaConfig[item.src];
              return mediaInfo ? (
                <div key={`item_${index}`}>
                  <div className={classes.blogContainer}>
                    <ScaledImage
                      src={item.src}
                      width={mediaInfo.width}
                      height={mediaInfo.height}
                      targetWidth={700}
                      noBorder
                    />
                    {
                      item.title ? [
                        <div className={classes.horizontalSpacer} />,
                        <div className={classes.title}>{item.title}</div>,
                        <div className={classes.horizontalSpacer} />
                      ] : null
                    }
                    {
                      item.description ? [
                        <div className={classes.horizontalSpacer} />,
                        (
                          <div
                            className={classes.description}
                            dangerouslySetInnerHTML={{ __html: `<div>${item.description}</div>` }}
                          />
                        ),
                        <div className={classes.horizontalSpacer} />
                      ] : null
                    }
                  </div>
                  <div className={classes.horizontalBigSpacer} />
                </div>
              ): (<div>{`Media info for ${item.src} is missing`}</div>);
            })
          }
        </div>
        <div className={classes.spacer} />
      </div>
      <CustomAppBar section="/blog" />
    </div>
  );
}

export default BlogController;
