import React from "react";
import { makeStyles } from "@mui/styles";
import CustomAppBar from "./components/CustomAppBar";
import ScaledImage from "./components/ScaledImage";
import ConceptArtConfig from "./data/concept-art.json";
import MediaConfig from "./data/media.json";

const useStyles = makeStyles(() => ({
  outerContainer: {
    overflow: "auto",
    backgroundColor: "#F3F3F3",
  },
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    overflow: "auto",
  },
  container: {
    width: "980px",
    minWidth: "980px",
    paddingTop: "120px",
  },
  spacer: {
    height: "100%",
    flexBasis: 0,
    flexGrow: 1,
  },
  horizontalSpacer: {
    width: "100%",
    height: "10px",
  },
  headline: {
    width: "100%",
    height: "60px",
    color: "#000000",
    font: "40px Allura",
    paddingLeft: "20px",
  }
}));

function ConceptArtController() {
  const classes = useStyles();

  React.useEffect(() => {
    document.title = "Concept Art | asenka.art";
  }, []);

  return (
    <div className={classes.outerContainer}>
      <div className={classes.root}>
        <div className={classes.spacer} />
        <div className={classes.container}>
          <div className={classes.headline}>Concept Art</div>
          {
            ConceptArtConfig.map((item, index) => {
              const mediaInfo = MediaConfig[item.src];
              return mediaInfo ? (
                <div key={`item_${index}`}>
                  <ScaledImage
                    src={item.src}
                    width={mediaInfo.width}
                    height={mediaInfo.height}
                    targetWidth={980}
                  />
                  <div className={classes.horizontalSpacer} />
                </div>
              ) : (<div>{`Media info for ${item.src} is missing`}</div>);
            })
          }
        </div>
        <div className={classes.spacer} />
      </div>
      <CustomAppBar section="/concept-art" />
    </div>
  );
}

export default ConceptArtController;
