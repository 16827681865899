import React from "react";
import { makeStyles } from "@mui/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomeController from "./HomeController";
import ConceptArtController from "./ConceptArtController";
import AnimationController from "./AnimationController";
import BlogController from "./BlogController";
import AboutController from "./AboutController";

const useStyles = makeStyles(() => ({
  root: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: "fixed",
    display: "flex",
    flexDirection: "column",
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#1876D2",
    },
  },
});

export default function AppRouter() {
  const classes = useStyles();
  const [dimensions, setDimensions] = React.useState({width: 0, height:0});
  const rootRef = React.useRef(null);

  React.useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: rootRef.current.clientWidth,
        height: rootRef.current.clientHeight
      });
    };    
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  });

  React.useEffect(() => {
    if (rootRef.current) {
      setDimensions({
        width: rootRef.current.clientWidth,
        height: rootRef.current.clientHeight
      });
    }
  }, [rootRef]);   

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root} ref={rootRef}>
        <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <HomeController width={dimensions.width} height={dimensions.height} />
            }
          />
          <Route
            path="/concept-art"
            element={<ConceptArtController />}
          />
          <Route
            path="/animation"
            element={<AnimationController />}
          />
          <Route
            path="/blog"
            element={<BlogController />}
          />
          <Route
            path="/about"
            element={
              <AboutController width={dimensions.width} height={dimensions.height} />
            }
          />
        </Routes>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}
