import React from "react";
import { makeStyles } from "@mui/styles";
import CustomAppBar from "./components/CustomAppBar";

const useStyles = makeStyles(() => ({
  text: {
    width: "390px",
    height: "410px",
    backgroundColor: "rgba(255, 255, 255, .5)",
    position: "absolute",
    top: "400px",
    left: "420px",
    borderRadius: "40px",
    padding: "25px",
    font: "17px Montserrat",
    textAlign: "justify",
  }
}));

type AboutControllerProps = {
  width: number;
  height: number;
};

function AboutController({
  width,
  height,
}: AboutControllerProps) {
  const classes = useStyles();

  React.useEffect(() => {
    document.title = "About | asenka.art";
  }, []);

  return (
    <div>
      <img
        src="/images/vishnya-1920x1080.jpg"
        style={{
          objectFit: "cover",
          width: width,
          height: height,
        }}
      />
      <div className={classes.text}>
        Hello, I am Anastasiya. Art has been my passion ever since I was a child. I used to be the kind of kid who doodled all over my classwork to the point that my teachers had to talk to my parents about my "unhealthy obsession" to constantly draw. And over these years...
        <br />
        <br />
        I haven't really changed! Yeah, I still draw a lot. And I love it! I love the process of creating something new, beautiful, and inspiring. My dream is to continue creating ideas through illustration, design, and motion.
        <br />
        <br />
        I wish to inspire people all over the world.
      </div>
      <CustomAppBar section="/about" />
    </div>
  );
}

export default AboutController;
