import React from "react";
import {
  Box,
  Toolbar,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => ({
  container: {
    position: "absolute",
    top: "20px",
    width: "100%",
    height: "60px",
    zIndex: "100",
    display: "flex",
    flexDirection: "row",
  },
  spacer: {
    height: "100%",
    flexBasis: 0,
    flexGrow: 1,
  },
  toolbar: {
    boxShadow: "none !important",
    height: "60px",
    lineHeight: "60px",
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    padding: "0px !important",
    font: "16px Montserrat",
    cursor: "pointer",
  },
  home: {
    width: "110px",
    backgroundColor: "rgba(255, 255, 255, .5)",
    color: "#919090",
    border: "1px solid #919090",
    borderTopLeftRadius: "30px",
    borderBottomLeftRadius: "30px",
    "&:hover": {
      backgroundColor: "rgba(185, 185, 185, 1) !important",
      color: "#FFFFFF",
    },
  },
  conceptArt: {
    width: "150px",
    backgroundColor: "rgba(255, 255, 255, .5)",
    color: "#919090",
    borderTop: "1px solid #919090",
    borderBottom: "1px solid #919090",
    borderRight: "1px solid #919090",
    "&:hover": {
      backgroundColor: "rgba(185, 185, 185, 1) !important",
      color: "#FFFFFF",
    },
  },
  animation: {
    width: "140px",
    backgroundColor: "rgba(255, 255, 255, .5)",
    color: "#919090",
    borderTop: "1px solid #919090",
    borderBottom: "1px solid #919090",
    borderRight: "1px solid #919090",
    "&:hover": {
      backgroundColor: "rgba(185, 185, 185, 1) !important",
      color: "#FFFFFF",
    },
  },
  blog: {
    width: "100px",
    backgroundColor: "rgba(255, 255, 255, .5)",
    color: "#919090",
    borderTop: "1px solid #919090",
    borderBottom: "1px solid #919090",
    borderRight: "1px solid #919090",
    "&:hover": {
      backgroundColor: "rgba(185, 185, 185, 1) !important",
      color: "#FFFFFF",
    },
  },
  about: {
    width: "110px",
    backgroundColor: "rgba(255, 255, 255, .5)",
    color: "#919090",
    borderTop: "1px solid #919090",
    borderBottom: "1px solid #919090",
    borderRight: "1px solid #919090",
    borderTopRightRadius: "30px",
    borderBottomRightRadius: "30px",
    "&:hover": {
      backgroundColor: "rgba(185, 185, 185, 1) !important",
      color: "#FFFFFF",
    },
  },
}));

type CustomAppBarProps = {
  section: string;
};

function CustomAppBar({
  section,
}: CustomAppBarProps) {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div className={classes.container}>
      <div className={classes.spacer} />
      <Toolbar variant="dense" className={classes.toolbar}>
        <Box
          className={classes.home}
          style={section === '/' ? {backgroundColor: "rgba(185, 185, 185, 0.5)", color: "#FFFFFF"} : {}}
          onClick={() => navigate('/')}
        >
          Home
        </Box>
        <Box
          className={classes.conceptArt}
          style={section === '/concept-art' ? {backgroundColor: "rgba(185, 185, 185, 0.5)", color: "#FFFFFF"} : {}}
          onClick={() => navigate('/concept-art')}
        >
          Concept Art
        </Box>
        <Box
          className={classes.animation}
          style={section === '/animation' ? {backgroundColor: "rgba(185, 185, 185, 0.5)", color: "#FFFFFF"} : {}}
          onClick={() => navigate('/animation')}
        >
          Animation
        </Box>
        <Box
          className={classes.blog}
          style={section === '/blog' ? {backgroundColor: "rgba(185, 185, 185, 0.5)", color: "#FFFFFF"} : {}}
          onClick={() => navigate('/blog')}
        >
          Blog
        </Box>
        <Box
          className={classes.about}
          style={section === '/about' ? {backgroundColor: "rgba(185, 185, 185, 0.5)", color: "#FFFFFF"} : {}}
          onClick={() => navigate('/about')}
        >
          About
        </Box>
      </Toolbar>
      <div className={classes.spacer} />
    </div>
  );
}

export default CustomAppBar;
