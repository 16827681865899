import React from "react";
import { withStyles, makeStyles } from "@mui/styles";
import { LinearProgress, Slider } from "@mui/material";
import ScaledImage from "./ScaledImage";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
  },    
  actionButton: {
    width: "100px",
    height: "34px",
  }, 
  title: {
    backgroundColor: "#D6D6D6",
    color: "#919090",
    font: "16px Montserrat",
    padding: "5px",
    display: "inline-block",
    borderRadius: "5px",
    border: "1px solid #919090",
    position: "absolute",
    top: 10,
    left: 10,
  },
  loading: {
    margin: "3px 0px 3px 0px",
  },
  progress: {
    width: "500px",
    height: "40px",
    lineHeight: "40px",
    backgroundColor: "#D6D6D6",
    color: "#919090",
    borderRadius: "5px",
    border: "1px solid #919090",
    position: "absolute",
    bottom: "10px",
    left: "110px",
    display: "flex",
    flexDirection: "row",
  },
  progressText: {
    padding: "0px 10px 0px 10px",
  }
}));

const CustomSlider = withStyles(theme => ({
  disabled: {
    color: "#919090",
  },
  track: {
    color: "#919090",
  },    
}))(Slider);

type VideoState =
  | "Initial"
  | "Load"
  | "PlayProgress"
  | "Play"
  | "Pause";

type VideoPlayerProps = {
  image: string;
  video: string;
  title?: string;
  width: number;
  height: number;  
  targetWidth: number;
};

function VideoPlayer({
  image,
  video,
  title,
  width,
  height,
  targetWidth,
}: VideoPlayerProps) {
  const classes = useStyles();
  const stateRef = React.useRef("Initial");
  const timerRef = React.useRef(null);
  const [state, setState] = React.useState<VideoState>("Initial");
  const [progress, setProgress] = React.useState(0);
  const videoRef = React.useRef(null);

  React.useEffect(() => {
    stateRef.current = state;
  }, [state]);

  React.useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  const play = () => {
    if (!videoRef.current) {
      return;
    }

    if (videoRef.current.ended) {
      videoRef.current.currentTime = 0;
      setProgress(0);
    }
    setState("PlayProgress");
    videoRef.current.play();
    clearTimeout(timerRef.current); 
    timerRef.current = setTimeout(() => {
      if (stateRef.current === "PlayProgress") {
        setState("Play");
      }
    }, 1000);
  }

  const pause = () => {
    setState("Pause");
    videoRef.current.pause();
  }
  
  React.useEffect(() => {
    if (videoRef.current) {
      const handleLoad = () => {
        play();
      };
      const handleProgress = () => {
        setProgress(videoRef.current.currentTime);
      };
      const handleEnd = () => {
        setState("Pause");
      }
      videoRef.current.addEventListener('canplaythrough', handleLoad);
      videoRef.current.addEventListener('timeupdate', handleProgress);
      videoRef.current.addEventListener('ended', handleEnd);
      

      return () => {
        videoRef.current.removeEventListener('canplaythrough', handleLoad);
        videoRef.current.removeEventListener('timeupdate', handleProgress);
        videoRef.current.removeEventListener('ended', handleEnd);
      }      
    }
  }, [videoRef]);   
  
  if (!image || !video) {
    return null;
  }

  const targetHeight = Math.round((height * targetWidth) / width);

  let imageElement = null;
  let overlayElement = null;
  if (state === "Initial" || state === "Load") {
    imageElement = (
      <div className={classes.overlay}>
        <ScaledImage
          src={image}
          width={width}
          height={height}
          targetWidth={targetWidth}
          noBorder
        />
      </div>      
    );
    overlayElement = (
      <div
        className={classes.overlay}
        style={{ width: targetWidth, height:  targetHeight }}
      >
        <div className={classes.title}>
          {title}
          {state === "Load" ? (
            <LinearProgress className={classes.loading} color="inherit" />
          ) : null}
        </div>
      </div>
    );
  } else if (state === "PlayProgress" || state === "Pause") {
    const secondToString = (second) => {
      const date = new Date(0);
      date.setSeconds(second);
      return date.toISOString().substr(14, 5);
    };
    overlayElement = (
      <div
        className={classes.overlay}
        style={{ width: targetWidth, height:  targetHeight }}
      >
        <div className={classes.title}>{title}</div>
        <div className={classes.progress}>
          <div className={classes.progressText}>
            {secondToString(Math.round(progress))}
          </div>
          <CustomSlider
            size="small"
            min={0}
            max={videoRef.current.duration}
            value={progress}
            disabled
            style={{marginTop: "6px"}}
          />          
          <div className={classes.progressText}>
            {secondToString(Math.round(videoRef.current.duration))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={classes.root}
      onClick={() => {
        if (videoRef.current) {
          if (state === "Initial") {
            setState("Load");
            videoRef.current.load();
          } else if (state === "Load") {
            // nothing to do
          } else if (state === "PlayProgress" || state === "Play") {
            pause();
          } else if (state === "Pause") {
            play();
          }
        }
      }}
    >
      {imageElement}
      <video
        preload="none"
        src={video}
        ref={videoRef}
        style={{ width: targetWidth, height:  targetHeight }}
      />
      {overlayElement}
    </div>
  );
}

export default VideoPlayer;
