import React from "react";
import { makeStyles } from "@mui/styles";
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import CustomAppBar from "./components/CustomAppBar";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles(() => ({
  stepper: {
    position: "absolute",
    bottom: "0px",
    left: "0px",
    width: "100%",
    height: "50px",
    zIndex: "100",
    display: "flex",
    flexDirection: "row",
  },
  stepperDotContainer: {
    width: "22px",
    height: "22px",
    borderRadius: "6px",
    padding: "5px",
  },
  stepperDot: {
    width: "12px",
    height: "12px",
    backgroundColor: "rgba(255, 255, 255, 0)",
    borderRadius: "6px",
    border: "2px solid #FFFFFF",
  },
  spacer: {
    height: "100%",
    flexBasis: 0,
    flexGrow: 1,
  },
  overlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: "0px",
    left: "0px",
  },
  slideBoxContainer: {
    position: "relative",
    top: "50%",
    left: "50%",
  },
  slideBox: {
    position: "relative",
    backgroundColor: "rgba(255, 255, 255, 0.5)"
  },
  slideTitle: {
    width: "100%",
    textAlign: "center",
    font: "25px Montserrat",
    color: "#0F0F0F",
    fontWeight: "300 !important",
    paddingTop: "20px",
  },
  slideDescription: {
    width: "100%",
    textAlign: "center",
    font: "14px Montserrat",
    color: "#2F2E2E",
    fontWeight: "300 !important",
    paddingTop: "10px",
  },
  slideAction: {
    width: "100%",
    textAlign: "center",
    font: "14px Montserrat",
    color: "#2F2E2E",
    fontWeight: "400 !important",
    paddingTop: "40px",
  },
}));

type HomeControllerProps = {
  width: number;
  height: number;
};

function HomeController({
  width,
  height,
}: HomeControllerProps) {
  const classes = useStyles();
  const [currentStep, setCurrentStep] = React.useState(0);

  React.useEffect(() => {
    document.title = "asenka.art";
  }, []);

  const slideImages = [
    "/images/conceptart1.jpg",
    "/images/temple.jpg",
    "/images/overwatch1.jpg",
    "/images/fixik1.jpg",
    "/images/spirits_herd.jpg"
  ];
  const overlays = [
    (
      <div className={classes.slideBoxContainer}>
        <div
          className={classes.slideBox}
          style={{ top: "200px", left: "-320px", width: "340px", height: "170px" }}
        >
          <div className={classes.slideTitle}>Nordic Sea</div>
          <div className={classes.slideDescription}>Are you ready to play a new game?</div>
          <div className={classes.slideAction}>READ MORE</div>
        </div>
      </div>
    ),
    (
      <div className={classes.slideBoxContainer}>
        <div
          className={classes.slideBox}
          style={{ top: "340px", left: "180px", width: "340px", height: "120px" }}
        >
          <div className={classes.slideTitle}>TEMPLE</div>
          <div className={classes.slideDescription}>After sunset The Festival will begin</div>
        </div>
      </div>
    ),
    (
      <div className={classes.slideBoxContainer}>
        <div
          className={classes.slideBox}
          style={{ top: "250px", left: "50px", width: "340px", height: "170px" }}
        >
          <div className={classes.slideTitle}>Night in the City Clouds</div>
          <div className={classes.slideDescription}>I hope you aren't afraid of heights</div>
          <div className={classes.slideAction}>NOT AFRAID</div>
        </div>
      </div>
    ),
    (
      <div className={classes.slideBoxContainer}>
        <div
          className={classes.slideBox}
          style={{ top: "200px", left: "-500px", width: "340px", height: "170px" }}
        >
          <div className={classes.slideTitle}>L d i n k a</div>
          <div className={classes.slideDescription}>Want to know my story?</div>
          <div className={classes.slideAction}>FIND OUT</div>
        </div>
      </div>
    ),
    (
      <div className={classes.slideBoxContainer}>
        <div
          className={classes.slideBox}
          style={{ top: "240px", left: "-250px", width: "340px", height: "75px" }}
        >
          <div className={classes.slideTitle}>Spirit's Herd</div>
        </div>
      </div>
    ),
  ];

  return (
    <div>
      <AutoPlaySwipeableViews
        axis="x"
        index={currentStep}
        onChangeIndex={setCurrentStep}
        interval={10000}
        enableMouseEvents
      >
        {
          slideImages.map((image, index) => (
            <div key={image} style={{position: "relative"}}>
              <img
                src={image}
                style={{
                  objectFit: "cover",
                  width: width,
                  height: height,
                }}
              />
              <div className={classes.overlay}>
                {overlays[index]}
              </div>
            </div>
          ))
        }
      </AutoPlaySwipeableViews>
      <CustomAppBar section="/" />
      <div className={classes.stepper}>
        <div className={classes.spacer} />
        {
          Array.from({length: 5}).map((item, index) => {
            return (
              <div
                key={`stepper_${index}`}
                className={classes.stepperDotContainer}
                onClick={() => setCurrentStep(index)}
              >
                <div
                  className={classes.stepperDot}
                  style={index === currentStep ? {backgroundColor: "#FFFFFF"} : {}}
                />
              </div>
            );
          })
        }
        <div className={classes.spacer} />
      </div>
    </div>
  );
}

export default HomeController;
