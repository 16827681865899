import React from "react";
import { Box, CardMedia } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  image: {
    backgroundSize: "contain",
    border: (props: any) =>
      props.noBorder === true ? "none" : "1px solid #C4C4C4",
  },
}));

type ScaledImageProps = {
  src: string;
  width: number;
  height: number;  
  targetWidth: number;
  noBorder?: boolean;
};

function ScaledImage({
  src,
  width,
  height,
  targetWidth,
  noBorder,
}: ScaledImageProps) {
  const classes = useStyles({ noBorder });

  return (
    <Box>
      <CardMedia
        image={src}
        className={classes.image}
        style={{
          width: targetWidth,
          height:  Math.round((height * targetWidth) / width),
        }}
      />
    </Box>
  );
}

export default ScaledImage;
